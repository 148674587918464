.header-component {
    position: relative;
    z-index: 3;

    .side-menu {
        position: fixed;
        top: 0;
        bottom: 0;
        left: -330px;
        width: 280px;
        padding: 12px;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        background: var(--primary);
        transition: left .5s ease-in-out;
        z-index: 3;

        @media screen and (max-width: 480px) {
            width: 192px;
        }

        &.active {
            left: 0;
        }

        .box-header {
            a {
                display: block;
                width: 100%;
                height: 177px;
                font-size: 0;
            }
        }

        .menu-options {
            ul {
                padding: 0;

                .option {
                    margin-top: 8px;
                    padding-bottom: 8px;
                    padding-left: 15px;
                    border-bottom: 1px dashed rgb(204, 204, 204);
                    font-size: 17px;
                    font-weight: bold;
                    color: #FFFFFF;
                    text-align: left;
                    list-style-type: none;

                    a {
                        display: block;
                        width: fit-content;
                        text-decoration: none;

                        &:after {
                            display: block;
                            width: 0;
                            height: 2px;
                            background: var(--secundary);
                            content: '';
                            transition: width .4s ease-in-out;
                        }

                        &:hover {
                            &:after {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    .container {
        background: var(--primary);

        .header-container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            min-height: 60px;
            margin: 0px 46px;
            background: transparent;

            @media screen and (max-width: 890px) {
                flex-direction: column;
            }

            @media screen and (max-width: 767px) {
                position: fixed;
                top: 0;
                left: 0;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: 70px;
                margin: 0;
                padding: 0;
                box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
                background: #FFFFFF;
                z-index: 2;
            }

            .container {
                display: flex;
                justify-content: space-between;
                align-items: center;
                max-width: 1336px;
                width: 100%;
                margin: 0 auto;

                @media screen and (max-width: 767px) {
                    padding: 0 22px;
                }

                .hamburger-container {
                    display: none;

                    &.its-visible {
                        display: block;
                    }
                }

                .box-header {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @media screen and (min-width: 768px) {
                        min-width: 250px;
                        height: 64px;
                    }

                    a {
                        width: 100%;
                        height: 75px;
                        font-size: 0;

                        @media screen and (max-width: 767px) {
                            width: 140px;
                            height: 70px;
                        }
                    }

                    button {
                        min-height: 49px;
                        padding: 14px 18px;
                        border: 0px;
                        border-radius: 40px;
                        background: var(--primary);
                        font-size: 14px;
                        color: #FFFFFF;
                        transition: background 0.5s ease 0s;
                        cursor: pointer;

                        &:hover {
                            background: var(--tertiaryVariation1);
                        }

                        span {
                            font-weight: bold;
                        }
                    }
                }

                .box-menu {
                    display: flex;
                    align-items: center;
                    width: fit-content;

                    &.is-hidden {
                        display: none;
                    }

                    .ul-menu {
                        display: flex;
                        margin: 0 auto;
                        padding: 0px;

                        li {
                            position: relative;
                            margin: 0 19px;
                            padding: 0;
                            font-size: 16px;
                            font-weight: 300;
                            color: #FFF;
                            line-height: 32px;
                            transition: color 0.5s;
                            cursor: pointer;
                            list-style-type: none;

                            &:before {
                                content: '';
                                display: block;
                                position: absolute;
                                bottom: 0;
                                width: 0;
                                height: 2px;
                                right: 0;
                                background: #FFF;
                                transition: all .3s ease-in-out;
                            }

                            &:hover {
                                &:before {
                                    width: 100%;
                                    left: 0;
                                }
                            }

                            p {
                                margin: auto;
                                font-size: 14px;
                                font-weight: 500;
                                line-height: 26px;

                                a {
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }

                .my-account-container {
                    width: 250px;

                    @media screen and (max-width: 767px) {
                        display: none;
                    }

                    a {
                        font-size: 0;
                        display: block;
                        width: 126px;
                        height: 38px;
                        background: url('../../../assets/svg/my-account-logo.svg') center no-repeat;
                    }
                }
            }
        }
    }

    .floating-header {
        display: none;

        @media screen and (min-width: 768px) {
            display: block;
            position: fixed;
            top: -100px;
            left: 0;
            width: 100%;
            height: 95px;
            box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
            background: #FFF;
            transition: all .6s ease-in-out;
            z-index: 1;

            &.fixed-header {
                top: 0;
            }

            .header-container {
                min-height: 100%;
                padding-top: unset;
                margin: 0;

                .container {
                    max-width: unset;

                    .box-header {
                        a {
                            width: 100%;
                            height: 80px;
                        }
                    }
                }
            }
        }
    }

    .backdrop {
        position: fixed;
        top: 0;
        left: 100vw;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        transition: left .5s ease-in-out;
        cursor: pointer;
        z-index: 2;

        &.active {
            left: 0;
        }
    }
}
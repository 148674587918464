.error-image-overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    @media screen and (max-width: 767px) {
        padding: 42px 0;
    }

    .error-image-container {
        display: inline-block;
        position: relative;
        width: 250px;
        height: 350px;
        background-image: url('https://i.imgur.com/A040Lxr.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
    }

    .error-text {
        width: 90%;
        margin: 0 auto;
        font-size: 42px;
        color: var(--secundary);
        text-align: center;

        @media screen and (max-width: 767px) {
            font-size: 32px;
        }
    }
}
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700&display=swap');

:root {
  --tertiaryVariation1: #29aefd;
  --tertiaryVariation2: #72dd21;
  --tertiaryVariation3: #56D59A;
  --textColor: #A1A1A1;
  --borderColor: #D8D8D8;
  --helperText: #666666;
  --darkText: #505050;
  --primary: #00995D;
  --secundary: #4CAF50;
  --secundaryVariation1: #4CAF50;
  --tertiary: #70bcf9;
}

body {
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'Work Sans', sans-serif;
}

#root {
  position: relative;
  min-height: 100vh;
}

a:link button {
  outline: none !important;
}

button {
  outline: none !important;
}

.is-hidden {
  display: none !important;
}

*::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}
*::-webkit-scrollbar-track {
  background-color: #F1F1F1;
}
*::-webkit-scrollbar-thumb {
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.3);
}
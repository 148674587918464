main {
    position: relative;
    min-height: 100%;
    margin: 2vw 0 0;
    background: #FFFFFF;
}

@media screen and (max-width: 1336px) {
    main {
        margin: 9vw 0 0;
    }
  }

@media screen and (max-width: 767px) {
    main {
        margin: 120px 0 0;
    }
}
.footer {
    position: relative;
    bottom: 0;
    max-width: 100%;
    background-color: var(--secundary);
    padding: 0 64px;
    text-align: center;
    margin-top: 56px;

    a {
        font-size: 0;
        margin: auto;
        display: block;
        height: 71px;
    }

    @media screen and (min-width: 1600px) {
        padding: 0;
        position: fixed;
        bottom: 0;
        width: 100%;
        max-width: 100%;
        left: 0;
        right: 0;
    }
}
.loading-overlay {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #FFF;
    z-index: 1;

    @keyframes grow-shrink {
        0% {
            transform: translate(-50%, -50%);
        }

        50% {
            transform: translate(-50%, -50%) scale(1.5);
        }

        100% {
            transform: translate(-50%, -50%);
        }
    }

    .image-container {
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 20%;
        height: 20%;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        animation: grow-shrink 1s ease-in-out infinite;

        @media screen and (max-width: 767px) {
            width: 60px;
            height: 60px;
        }
    }
}